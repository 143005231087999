import {
    GlobalFooter,
    Button,
    GlobalFooterRow,
    GlobalFooterColumn,
    GlobalFooterCopyrightModule,
    GlobalFooterLinksModule,
    useIsDesktop,
    Text,
    Link,
} from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import styles from './footer.module.css';
import getConfig from 'next/config';
import { isWithinWorkingHours } from '../header/helpers';
import { utcToZonedTime } from 'date-fns-tz';
import { footerComplianceLinks } from './constants';
import React, { useContext, useEffect, useState } from 'react';
import { PagesContext } from '../../state/pages.context';
import { IFooterLink } from '../../interfaces/footer';
import { IMarket } from '../../interfaces/shared';
import { getHyphenatedText, getWorkingHoursHelper } from '../../shared/helpers';
import { getBookingLink } from '../../shared/helpers';
import { useRouter } from 'next/router';
import { IWorkingHours } from '../../interfaces/zesty';
import { FTDRLogo2 } from '../../svgs/ftdr-logo-2';

interface IProps {
    readonly links: IFooterLink[];
    market: IMarket | undefined;
    isOnBookingPage?: boolean;
    isFTDRTheme?: boolean;
    footerBookBtnLabel?: string;
}

export function Footer({
    links,
    market,
    isOnBookingPage,
    isFTDRTheme,
    footerBookBtnLabel,
}: IProps) {
    const [workingHours, setWorkingHours] = useState<IWorkingHours | null>(
        null,
    );
    const { availableServices } = useContext(PagesContext);
    const isDesktop = useIsDesktop(true);
    const now = new Date();
    const legalText = `© ${now.getFullYear()} Frontdoor Pro, Inc. © ${now.getFullYear()} Frontdoor Pro Home Solutions, Inc. All rights reserved. FRONTDOOR and FRONTDOOR PRO are trademarks of Frontdoor, Inc. used under license.`;

    const {
        publicRuntimeConfig: { FORMATTED_PHONE = '' },
    } = getConfig();

    const router = useRouter();

    const queryString = router.asPath.split('?')[1]
        ? router.asPath.split('?')[1]
        : '';

    const URLParams: Record<string, string | number> = {};

    Object.entries(router.query).forEach(([key, value]) => {
        if (queryString.includes(key) && key != 'superm') {
            URLParams[key] = `${value}`;
        }
    });

    useEffect(() => {
        (async () => {
            const workingHours = await getWorkingHoursHelper();
            setWorkingHours(workingHours);
        })();
    }, []);

    useEffect(() => {
        links.forEach(function (a) {
            a.link = a.link.replace(
                'city',
                market?.name ? getHyphenatedText(market?.name) : 'city',
            );
            a.link = a.link.replace(
                'marketId',
                market?.id ? market?.id : 'marketId',
            );
        });
    }, [market]);

    const showCallNumber =
        workingHours &&
        isWithinWorkingHours({
            date: utcToZonedTime(now, 'America/Los_Angeles'),
            workingHours,
        });

    const getLinks = () => {
        let linksPlusTrustArc = links.slice();
        const trustArcCookie = {
            linkText: 'TrustArcCookie',
            link: '',
            order: 0,
        };

        if (links.length % 2 === 0) {
            trustArcCookie.order = links.length + 1;

            linksPlusTrustArc = linksPlusTrustArc.concat([trustArcCookie]);
        } else {
            linksPlusTrustArc = linksPlusTrustArc.concat(trustArcCookie);
        }

        return linksPlusTrustArc;
    };

    return (
        <div
            className={clsx(
                isFTDRTheme && `footer-ftdr ${styles['footer-ftdr']}`,
            )}
        >
            <GlobalFooter variant="dark" className="px-2 md:px-3">
                <div id="footer-links-container">
                    <div
                        className={clsx(
                            'flex flex-wrap justify-center max-w-7xl',
                            { 'flex-col items-start': !isDesktop },
                            styles['links-row'],
                            isFTDRTheme &&
                                `links-row-ftdr ${styles['links-row-ftdr']}`,
                        )}
                    >
                        <div
                            className={clsx(
                                'mb-6',
                                !isFTDRTheme && 'mr-24',
                                isFTDRTheme && 'md:mr-24',
                            )}
                        >
                            <h6 className="text-white">Frontdoor Pro</h6>
                            <div className={styles['links-column']}>
                                {getLinks().map((link, i) =>
                                    link.linkText === 'TrustArcCookie' ? (
                                        <div id="teconsent"></div>
                                    ) : (
                                        <a
                                            key={i}
                                            href={
                                                queryString
                                                    ? link.link +
                                                      '?' +
                                                      queryString
                                                    : link.link
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {link.linkText}
                                        </a>
                                    ),
                                )}
                            </div>
                        </div>

                        {availableServices.length > 0 && (
                            <div className="mr-8 mb-6">
                                <h6 className="text-white">Services</h6>
                                <div className={styles['links-single-column']}>
                                    {availableServices.map((service, i) => (
                                        <a
                                            key={i}
                                            href={getBookingLink({
                                                market,
                                                serviceType: service,
                                                params: URLParams,
                                            })}
                                        >
                                            {service.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div
                            className={clsx(
                                'text-center md:text-right my-auto relative min-w-fit',
                                styles['button-row'],
                                isFTDRTheme && styles['button-row-ftdr'],
                            )}
                        >
                            {isOnBookingPage ? (
                                <Button
                                    id="book-now-footer"
                                    color="secondary"
                                    variant="filled"
                                    label="Book Now!"
                                    size="medium"
                                    shape={isFTDRTheme ? 'pill' : 'rounded'}
                                    onClick={() =>
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                    }
                                />
                            ) : (
                                <Button
                                    id="book-now-footer"
                                    color="secondary"
                                    variant="filled"
                                    label="Book Now!"
                                    size="medium"
                                    shape={isFTDRTheme ? 'pill' : 'rounded'}
                                    href={getBookingLink({
                                        market: market,
                                        params: URLParams,
                                    })}
                                />
                            )}

                            {showCallNumber && (
                                <Link
                                    href={`tel:${
                                        FORMATTED_PHONE || '(844) 473-7849'
                                    }`}
                                >
                                    <Text
                                        className={clsx(
                                            'ml-1 inline text-3',
                                            isFTDRTheme && styles['phone-ftdr'],
                                        )}
                                        color="white"
                                        variant="caption"
                                    >
                                        <strong className="hover:underline">
                                            or Call{' '}
                                            {FORMATTED_PHONE ||
                                                '(844) 473-7849'}
                                        </strong>
                                    </Text>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className={clsx(
                        styles['base-row'],
                        'max-w-5xl',
                        !isFTDRTheme && 'pb-4',
                    )}
                >
                    <GlobalFooterRow
                        direction={isDesktop ? 'row' : 'col'}
                        className={clsx(
                            isDesktop
                                ? 'justify-between py-7'
                                : isFTDRTheme
                                ? 'px-2 pt-7 pb-3'
                                : 'px-4 py-7',
                        )}
                    >
                        <GlobalFooterColumn
                            grow
                            col={isDesktop ? 7 : undefined}
                            className={clsx(
                                'mr-6 max-w-3xl mb-4',
                                styles['copyright'],
                            )}
                        >
                            <div className="flex pt-1">
                                <div className="flex self-center">
                                    <FTDRLogo2
                                        color={
                                            isFTDRTheme ? '#F34113' : 'white'
                                        }
                                    />
                                </div>
                                <GlobalFooterCopyrightModule
                                    legalText={legalText}
                                    className="flex-row md:flex-column pt-1 ml-3"
                                />
                            </div>
                        </GlobalFooterColumn>

                        <GlobalFooterColumn shrink={false}>
                            <GlobalFooterRow
                                direction={isDesktop ? 'row' : 'col'}
                                className={
                                    isDesktop ? 'items-center h-full' : '-ml-2'
                                }
                            >
                                <GlobalFooterColumn
                                    className={clsx('flex items-center -mr-1', {
                                        'py-4': !isDesktop,
                                    })}
                                >
                                    <GlobalFooterLinksModule
                                        linkType="small"
                                        orientation="horizontal"
                                        links={footerComplianceLinks}
                                    ></GlobalFooterLinksModule>
                                </GlobalFooterColumn>
                            </GlobalFooterRow>
                        </GlobalFooterColumn>
                    </GlobalFooterRow>
                </div>

                {isFTDRTheme && footerBookBtnLabel && (
                    <div
                        className={clsx(
                            'md:hidden bottom-0 w-full footer-btn-wrapper pb-8 px-2',
                        )}
                    >
                        <Button
                            variant="filled"
                            width="full"
                            shape="pill"
                            label={footerBookBtnLabel}
                            onClick={() =>
                                window.scrollTo({ top: 0, behavior: 'smooth' })
                            }
                        />
                    </div>
                )}
            </GlobalFooter>
        </div>
    );
}
